import React from "react";
import { GoogleReCaptchaProvider } from "react-google-recaptcha-v3";

export default function({ children, useReacptcha, recaptchaKey, captchaProvider, hcaptchaKey, ...props }) {
  console.log('Security Provider', { useReacptcha, recaptchaKey, captchaProvider, hcaptchaKey });

  if (captchaProvider === 'hcaptcha') {
    return (
        <>{children}</>
    )
  }

  return useReacptcha && recaptchaKey ? (
    <GoogleReCaptchaProvider
      reCaptchaKey={recaptchaKey}
      useRecaptchaNet={true}
      useEnterprise={true}
      hcaptchaKey={hcaptchaKey}
      provider={captchaProvider}
    >
      {children}
    </GoogleReCaptchaProvider>
  ) : (
    <>{children}</>
  );
}
