import _ from "lodash";
import React from "react";
import ReactPixel from "react-facebook-pixel";
import { connect } from "react-redux";
import withTranslation from "../../hocs/withTranslation";
import { getStyles } from "../../store/selectors";
import {
  resetCodeVerification,
  setProfileInSignupFlowMode,
  verifyCode,
} from "../../store/user/actions";
import { getParams, setParams } from "../../utils/location";
import AppContainer from "../AppContainer";
import Button from "../Button";
import ButtonGroup from "../ButtonGroup";
import ErrorMessage from "../ErrorMessage";
import ExternalLink from "../ExternalLink";
import { CodeInput, InputGroup } from "../Inputs";
import { LOGIN_TYPES } from "../LoginView";
import * as styles from "./index.module.scss";
import SecurityProvider from "../security-provider";

const CODE = "code";
class VerifyCodeView extends React.Component {
  inputRefs = {};

  componentDidMount() {
    this.props.reset();
  }

  registerInput = (key) => (ref) => {
    this.inputRefs[key] = ref;
  };

  onSubmit = (phoneNumber) => () => {
    const { inStoreSignupBranchId } = this.props;

    if(this.props.googleReCaptchaProps) {
      this.props.googleReCaptchaProps.executeRecaptcha("giftcard").then(
        (recaptchaToken) => {
          this.inputRefs[CODE].validate((codeInputError, code) => {
            if (!codeInputError && code) {
              this.inputRefs[CODE].inputElement.focus();
              this.inputRefs[CODE].inputElement.blur();
              this.props.onSubmit({
                code: code,
                phoneNumber,
                ...(inStoreSignupBranchId && { branchId: inStoreSignupBranchId }),
                recaptchaToken,
              });
            }
          });
        }
      );
    } else {
      this.inputRefs[CODE].validate((codeInputError, code) => {
        if (!codeInputError && code) {
          this.inputRefs[CODE].inputElement.focus();
          this.inputRefs[CODE].inputElement.blur();
          this.props.onSubmit({
            code: code,
            phoneNumber,
            ...(inStoreSignupBranchId && { branchId: inStoreSignupBranchId }),
          });
        }
      });
    }
  };

  fireFBPixelLeadEventsIfNeeded = () => {
    const {
      business: { trackings },
    } = this.props;

    const fbLead = _.find(
      _.flatMap(_.filter(trackings, { type: "facebook" }), "events"),
      { type: "Lead" },
    );
    if (fbLead) {
      ReactPixel.track("Lead");
    }
  };

  componentDidUpdate(prevProps) {
    if (
      this.props.user.verificationCodeState.sent &&
      !prevProps.user.verificationCodeState.sent
    ) {
      if (
        getParams(this.props.location).from === "signup" ||
        this.props.user.authMode === "signup"
      ) {
        this.fireFBPixelLeadEventsIfNeeded();
      }
      this.props.nextTransition && this.props.nextTransition();
    }
  }

  errorMessage = (error) => {
    if (error.triesLeft) {
      if (error.triesLeft > -1) {
        return "Please enter the verification code as it was received";
      } else {
        return "You passed the try limit, please try again in a few minutes";
      }
    }
    return "We had a connection problem. Please try again";
  };

  render() {
    const {
      T,
      appStyles,
      user: {
        lastPhoneSent,
        verificationCodeState: { sending, sent, error },
      },
      modalMode,
      closeVerifyCode,
      isInStoreSignup,
    } = this.props;

    const params = getParams(this.props.location);

    const { lastPhoneSent: lastPhoneSentParam, from } = params;

    const phoneNumber = lastPhoneSent || lastPhoneSentParam;

    const resendTarget =
      from === "signup"
        ? setParams("/signup", params)
        : setParams("/login", params);

    const { PageHeader = {} } = appStyles;

    return (
      <AppContainer.Content
        appStyles={appStyles}
        style={isInStoreSignup ? { paddingTop: 0 } : {}}
        modalMode={modalMode}
        animate
      >
        <AppContainer.CenteredColumn>
          {!isInStoreSignup && <h2 style={PageHeader}>{T("Enter Code")}</h2>}
          <p style={isInStoreSignup ? { color: appStyles.accentColor } : null}>
            {T("Please enter the code we sent you via SMS to")} {phoneNumber}
          </p>
        </AppContainer.CenteredColumn>
        <InputGroup appStyles={appStyles} T={T}>
          <CodeInput
            refEl={this.registerInput("code")}
            placeholder={T("Verification Code")}
          />
        </InputGroup>
        <ButtonGroup appStyles={appStyles}>
          <Button
            onClick={this.onSubmit(phoneNumber)}
            appStyles={appStyles}
            centered
            loading={sending}
            completed={sent}
          >
            {T("Verify")}
          </Button>
        </ButtonGroup>
        {error && (
          <ErrorMessage appStyles={appStyles}>
            {T(this.errorMessage(error))}
          </ErrorMessage>
        )}
        <AppContainer.CenteredColumn>
          <p>
            {T("Didn't receive a code?")}
            <br />
            <span
              onClick={
                this.props.loginType !== LOGIN_TYPES.PAYMENT
                  ? closeVerifyCode
                  : () => this.props.nextTransition(true)
              }
              style={{
                color: appStyles.actionColor,
                ...appStyles.LinkButton,
              }}
              className={styles.Clickable}
            >
              {T("Resend")}
            </span>
            {` ${T("or")} `}
            <span
              onClick={
                this.props.loginType !== LOGIN_TYPES.PAYMENT
                  ? closeVerifyCode
                  : () => this.props.nextTransition(true)
              }
              style={{
                color: appStyles.actionColor,
                ...appStyles.LinkButton,
              }}
              className={styles.Clickable}
            >
              {T("Change phone number")}
            </span>
            {this.props.loginType == LOGIN_TYPES.PAYMENT && (
              <small
                style={{
                  display: "block",
                  marginTop: "5px",
                }}
              >
                {T("& By Checking this, you agree to our")} <br />
                <ExternalLink
                  appStyles={appStyles}
                  link={_.get(appStyles, "links.tos")}
                  fallbackLink={setParams("/tos", {
                    backPath:
                      _.get(this.props, "location.pathname") +
                      _.get(this.props, "location.search"),
                  })}
                >
                  {T("Terms of Service")}
                </ExternalLink>{" "}
                {T("and")}{" "}
                <ExternalLink
                  appStyles={appStyles}
                  link={_.get(appStyles, "links.privacy")}
                  fallbackLink={setParams("/privacy-policy", {
                    backPath:
                      _.get(this.props, "location.pathname") +
                      _.get(this.props, "location.search"),
                  })}
                >
                  {T("Privacy Policy")}
                </ExternalLink>
              </small>
            )}
          </p>
        </AppContainer.CenteredColumn>
      </AppContainer.Content>
    );
  }
}

const VerifyCodePage = withTranslation(
  ({
    T,
    pageContext: { business, businessAppConfiguration },
    user,
    verifyCode,
    resetCodeVerification,
    location,
    setProfileInSignupFlowMode,
    modalMode,
    closeVerifyCode,
    handleClose,
    appStyles,
    app: { loginType },
    isInStoreSignup,
    inStoreSignupBranchId,
    nextTransition,
  }) => {
    const { useRecaptchaInGiftCard } = businessAppConfiguration;
    const { recaptchaKey, captchaProvider, hcaptchaKey } = businessAppConfiguration;
    const loadWithSecurityProvider = recaptchaKey && useRecaptchaInGiftCard;
    return loadWithSecurityProvider ? (
      <SecurityProvider
        useReacptcha={useRecaptchaInGiftCard}
        recaptchaKey={recaptchaKey}
        captchaProvider={captchaProvider}
        hcaptchaKey={hcaptchaKey}
      >
        <VerifyCodeView
          T={T}
          appStyles={appStyles}
          business={business}
          location={location}
          reset={resetCodeVerification}
          onSubmit={verifyCode}
          user={user}
          setProfileInSignupFlowMode={setProfileInSignupFlowMode}
          modalMode={modalMode}
          closeVerifyCode={closeVerifyCode}
          handleClose={handleClose}
          loginType={loginType}
          isInStoreSignup={isInStoreSignup}
          inStoreSignupBranchId={inStoreSignupBranchId}
          nextTransition={nextTransition}
        />
      </SecurityProvider>
    ) : (
      <VerifyCodeView
        T={T}
        appStyles={appStyles}
        business={business}
        location={location}
        reset={resetCodeVerification}
        onSubmit={verifyCode}
        user={user}
        setProfileInSignupFlowMode={setProfileInSignupFlowMode}
        modalMode={modalMode}
        closeVerifyCode={closeVerifyCode}
        handleClose={handleClose}
        loginType={loginType}
        isInStoreSignup={isInStoreSignup}
        inStoreSignupBranchId={inStoreSignupBranchId}
        nextTransition={nextTransition}
      />
    );
  }
);

const mapStateToProps = (state, props) => {
  const { user, app } = state;

  return {
    user,
    app,
    appStyles: getStyles(state, props),
  };
};

const mapDispatchToProps = (dispatch, props) => {
  return {
    resetCodeVerification: () => dispatch(resetCodeVerification),
    verifyCode: (params) => dispatch(verifyCode(params)),
    setProfileInSignupFlowMode: () => dispatch(setProfileInSignupFlowMode()),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(VerifyCodePage);
