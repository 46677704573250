import React, { useEffect, useState, useRef } from 'react';
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3';
import HCaptcha from '@hcaptcha/react-hcaptcha';

const withCaptcha = (WrappedComponent) => {
  const WithCaptchaComponent = (props) => {
    const { pageContext } = props;
    const captchaProvider = pageContext?.businessAppConfiguration?.captchaProvider;
    
    const { executeRecaptcha: executeGoogleRecaptcha } = useGoogleReCaptcha();
    const hcaptchaRef = useRef(null);
    const [isReady, setIsReady] = useState(false);

    useEffect(() => {
      if (
        (captchaProvider === 'recaptcha' && executeGoogleRecaptcha) ||
        (captchaProvider === 'hcaptcha')
      ) {
        setIsReady(true);
      }
    }, [executeGoogleRecaptcha, captchaProvider]);

    const handleExecuteRecaptcha = async (action) => {
      console.log(`WithCaptcha: handleExecuteRecaptcha captchaProvider=${captchaProvider} action=${action}`)
      if (!captchaProvider) {
        throw new Error('Captcha provider not specified in configuration');
      }

      if (captchaProvider === 'recaptcha') {
        if (!executeGoogleRecaptcha) {
          throw new Error('reCAPTCHA has not been initialized');
        }
        
        try {
          return await executeGoogleRecaptcha(action);
        } catch (error) {
          console.error('reCAPTCHA execution failed:', error);
          throw error;
        }
      }

      if (captchaProvider === 'hcaptcha') {
        if (!hcaptchaRef.current) {
          throw new Error('hCaptcha has not been initialized');
        }

        try {
          const result = await hcaptchaRef.current.execute({ async: true });
          return result.response;
        } catch (error) {
          console.error('hCaptcha execution failed:', error);
          throw error;
        }
      }

      throw new Error(`Unsupported captcha provider: ${captchaProvider}`);
    };

    if (!isReady) {
      return null; // Or a loading component
    }

    return (
      <>
        {captchaProvider === 'hcaptcha' && (
          <HCaptcha
            sitekey={pageContext?.businessAppConfiguration?.hcaptchaKey}
            ref={hcaptchaRef}
            size="invisible"
          />
        )}
        <WrappedComponent
          {...props}
          googleReCaptchaProps={{ 
            executeRecaptcha: handleExecuteRecaptcha,
          }}
        />
      </>
    );
  };

  WithCaptchaComponent.displayName = `WithCaptcha(${
    WrappedComponent.displayName || WrappedComponent.name || 'Component'
  })`;

  return WithCaptchaComponent;
};

export default withCaptcha;
